<template>
	<div>
		<div class="flex header">
			<span @click="$router.push({path:'/index/copyingRecords'})">抄单记录 ></span>
			<h2 style="margin-left: 10px;">车辆详情</h2>
		</div>
		<div class="left common">
			<h3>车牌号码：{{ carNo }}</h3>
			<p>有效时期：{{ memberInfo.startDate }}至{{ memberInfo.endDate }}</p>
			<p>车主姓名：{{ memberInfo.carOwner }}</p>
			<p>电话号码：{{ memberInfo.ownerTel }}</p>
			<p>公司名称：{{ memberInfo.ownerCompany }}</p>
			<p>办理时间：{{ memberInfo.applyDate | formatTimer }}</p>
			<p>收费停车场：{{ memberInfo.chargeParking }}</p>
			<p>备注：{{ memberInfo.remark }}</p>
			<p>办理人：{{ memberInfo.dataHandler }}</p>
		</div>
		<div class="right common">
			<h3>进出场记录</h3>
			<el-table
					:data="tableData.slice((page.current - 1)*page.size,page.current*page.size)"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 322px)"
					style="width: 100%">
				<el-table-column
						prop="chargeParking" align="center" show-overflow-tooltip
						label="停车场">
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="进场时间">
					<template slot-scope="scope">
						{{ scope.row.inTime | formatTimer }}
					</template>
				</el-table-column>
				<el-table-column align="center" show-overflow-tooltip
												 label="出场时间">
					<template slot-scope="scope">
						<span v-if="scope.row.outTime">{{ scope.row.outTime | formatTimer }}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="停车时长">
					<template slot-scope="scope">
						<span v-if="scope.row.stopTime">{{ scope.row.stopTime }}</span>
						<span v-else>{{ scope.row.inTime | toDayDiff }}天</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="应收金额">
					<template slot-scope="scope">
						<span v-if="scope.row.needChargeAmount">{{ scope.row.needChargeAmount }}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column
						align="center" show-overflow-tooltip
						label="实收金额">
					<template slot-scope="scope">
						<span v-if="scope.row.realChargeAmount">{{ scope.row.realChargeAmount }}</span>
						<span v-else>-</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="tableData.length">
			</el-pagination>
		</div>
		<div style="clear: both"></div>
	</div>

</template>

<script>
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "copyingDetail",
	data() {
		return {
			tableData: [],
			page: {
				current: 1,
				size: 11,
				total: 0
			},
			carNo: this.$route.query.carNo,
			memberInfo: {}
		};
	},
	mounted() {
		this.getData();
		this.getMemberInfoByCarNo();
	},
	methods: {
		handleCurrentChange(val) {
			this.page.current = val;
			//this.getData();
		},
		getData() {
			this.$get("/car/sentry-income-info/detail", {
				carNo: this.carNo
			}).then(res => {
				console.log(res.data.data);
				this.tableData = res.data.data;
			});
		},
		getMemberInfoByCarNo() {
			this.$get("/car/member-info/getMemberInfoByCarNo", {
				carNo: this.carNo
			}).then(res => {
				this.memberInfo = res.data.data;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/copyingDetail";

.header {
	color: #FFFFFF;

	span {
		cursor: pointer;
	}
}
</style>
